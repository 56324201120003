<template>
  <div>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="时间">
                <a-range-picker
                  @change="time"
                  format="YYYY-MM-DD HH:mm"
                  :show-time="{ format: 'HH:mm' }">
                  <template slot="dateRender" slot-scope="current">
                    <div class="ant-calendar-date" :style="getCurrentStyle(current)" >
                      {{ current.date() }}
                    </div>
                  </template>
                </a-range-picker>
              </a-form-item>
            </a-col>
            <a-col :md="4" :sm="24">
              <a-button type="primary" @click="loadData">查询</a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>

      <table border="0" cellspacing="0" cellpadding="0" v-if="orderStatisticsResource.pay_method.length > 0">
        <tr>
          <th :colspan="wechatPayMethodList.length * 2" :style="mystyle">微信</th>
          <th :colspan="orderStatisticsResource.pay_method.length * 2" :style="mystyle">柜台</th>
        </tr>
        <tr>
          <th :colspan="wechatPayMethodList.length" :style="mystyle">销售</th>
          <th :colspan="wechatPayMethodList.length" :style="mystyle">退款</th>
          <th :colspan="orderStatisticsResource.pay_method.length" :style="mystyle">销售</th>
          <th :colspan="orderStatisticsResource.pay_method.length" :style="mystyle">退款</th>
        </tr>
        <tr>
          <td v-for="(item, index) in payMethodList" :key="index">
            {{ item.name }}
          </td>
        </tr>
        <tr>
          <td v-for="(item, index) in wechatPayMethodList" :key="index">
            {{(orderStatisticsResource.wechat_income.get(item.id) || 0) | moneyToYuan }}
          </td>
          <td v-for="(item, index) in wechatPayMethodList" :key="index">
            {{(orderStatisticsResource.wechat_refund.get(item.id) || 0) | moneyToYuan }}
          </td>
          <td v-for="(item, index) in orderStatisticsResource.pay_method" :key="index">
            {{(orderStatisticsResource.counter_income.get(item.id) || 0) | moneyToYuan }}
          </td>
          <td v-for="(item, index) in orderStatisticsResource.pay_method" :key="index">
            {{(orderStatisticsResource.counter_refund.get(item.id) || 0) | moneyToYuan }}
          </td>
        </tr>
        <tr>
          <td :colspan="wechatPayMethodList.length" :style="mystyle">
            <span style="color: dodgerblue">
              {{ this.payMethodTotal[0] | moneyToYuan }}
            </span>
          </td>
          <td :colspan="wechatPayMethodList.length" :style="mystyle">
            <span style="color: red">
              {{ this.payMethodTotal[1] | moneyToYuan }}
            </span>
          </td>
          <td :colspan="orderStatisticsResource.pay_method.length" :style="mystyle">
            <span style="color: dodgerblue">
              {{ this.payMethodTotal[2] | moneyToYuan }}
            </span>
          </td>
          <td :colspan="orderStatisticsResource.pay_method.length" :style="mystyle">
            <span style="color: red">
              {{ this.payMethodTotal[3] | moneyToYuan }}
            </span>
          </td>
        </tr>
      </table>
    </a-card>
  </div>
</template>

<script>
import { STable } from '@/components'
import { doRefund, orderToExamine, orderIncomeSummary } from '@/api/applet_shopmall_order'
export default {
  name: 'TableList',
  components: {
    STable
  },
  data () {
    return {
      mystyle: {
        width: '360px'
      },
      orderStatisticsResource: {
        pay_method: []
      },
      payMethodTotal: [],
      payMethodList: [],
      wechatPayMethodList: [],
      tip: '',
      refund_status_visible: false,
      select_status_data: '3',
      refund: 0,
      refund_reason: '',
      loading: false,
      confirmLoading: false,
      order_id: 0,
      // 创建窗口控制
      visible: false,
      mdl: {
        total_count: 0,
        total_price: 0
      },
      payconfirmLoading: false,
      // 创建窗口控制
      payvisible: false,
      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {}
    }
  },
  filters: {
    moneyToYuan (value) {
      return (value / 100).toFixed(2)
    }
  },
  methods: {
    handleDetail (record) {
      console.log(record.id)
      this.$router.push({ path: 'order/detail', query: { id: record.id } })
    },
  //  手动补订单库存数量
    onDeliverFirstChange (e) {
      console.log(e)
      if (!e.target.checked) {
        this.queryParam.self_delivery_first = undefined
      }
      this.$refs.table.refresh(true)
    },
    select_status () {
      if (this.select_status_data === '请选择') {
        this.$message.warning('请选择')
        return
      }
      const param = {
        status: this.select_status_data,
        order_id: this.order_id
      }
      if (this.select_status_data === '5') {
        param.refund = this.refund
      }
      this.confirm_loading = true
      orderToExamine(param).then(({ data }) => {
        console.log(data)
        this.select_status_data = '请选择'
        this.refund = 0
        this.order_id = 0
        this.select_status_visible = false
        this.confirm_loading = false
        this.$refs.table.refresh(true)
      })
    },
    select_refund () {
      if (this.refund === 0) {
        this.$message.warning('请输入退款金额')
        return
      }
      const param = {
        refund: Math.round(this.refund * 100),
        reason: this.refund_reason,
        order_id: this.order_id
      }

      doRefund(param).then(({ data }) => {
        console.log(data)
        this.refund = 0
        this.order_id = 0
        this.reason = ''
        this.refund_status_visible = false
        this.$refs.table.refresh(true)
      })
    },
    isRefund (status) {
      return status === 11 || status === 2 || status === 3
    },
    // 订单审核
    handle_to_examine (record) {
      this.select_status_visible = true
      // 2 审核通过，5 审核不通过
      this.order_id = record.id
    },
    // 订单退款
    handleRefund (record) {
      // this.refund_status_visible = true
      // // 2 审核通过，5 审核不通过
      // this.order_id = record.id
      this.$router.push({ path: 'order/detail_refund', query: { id: record.id } })
    },
    handleSelectChange (value) {
      this.select_status_data = value
      console.log(this.select_status_data, typeof this.select_status_data)
    },
    getCurrentStyle (current, today) {
      const style = {}
      if (current.date() === 1) {
        style.border = '1px solid #1890ff'
        style.borderRadius = '50%'
      }
      return style
    },
    time (dates, dateStrings) {
      console.log(dateStrings, '时间')
      if (dateStrings[0] === '') {
        this.queryParam.start_time = undefined
        this.queryParam.end_time = undefined
        return
      }
      this.queryParam.start_time = dateStrings[0]
      this.queryParam.end_time = dateStrings[1]
    },
    // 加载数据方法 必须为 Promise 对象
    loadData () {
      if (this.queryParam.start_time === undefined || this.queryParam.end_time === undefined) {
        this.$message.warning('请选择开始时间和结束时间')
      } else {
        orderIncomeSummary(Object.assign({}, this.queryParam))
          .then((res) => {
            this.payMethodList = []
            this.payMethodTotal = []
            this.wechatPayMethodList = []
            this.orderStatisticsResource = res
            this.wechatPayMethodList = this.orderStatisticsResource.pay_method.filter(i => i.code === 'wechat' || i.code === 'value_card' || i.code === 'mix')
            this.payMethodList.push(...this.wechatPayMethodList)
            this.payMethodList.push(...this.wechatPayMethodList)
            this.payMethodList.push(...this.orderStatisticsResource.pay_method)
            this.payMethodList.push(...this.orderStatisticsResource.pay_method)
            this.mystyle.width = (100 * this.orderStatisticsResource.pay_method.length) + 'px'
            this.orderStatisticsResource.wechat_income = this.changeData(this.orderStatisticsResource.wechat_income)
            this.orderStatisticsResource.wechat_refund = this.changeData(this.orderStatisticsResource.wechat_refund)
            this.orderStatisticsResource.counter_income = this.changeData(this.orderStatisticsResource.counter_income)
            this.orderStatisticsResource.counter_refund = this.changeData(this.orderStatisticsResource.counter_refund)
          })
      }
    },
    changeData (value) {
      let total = 0
      const methodCodes = this.orderStatisticsResource.pay_method.map(method => method.id)
      const map = new Map(value.filter(i => i[0] in methodCodes).map(element => {
        const data = []
        data.push(element[1])
        data.push(element[0])
       total += element[1]
        return element
      }))
      this.payMethodTotal.push(total)
      return map
    }
  }
}
</script>
<style scoped>
table {
  table-layout: fixed;
  border: 1px solid #ddd;
}

th, td {
  border-right: 1px solid #ddd;
  border-bottom: 0.5px solid #ddd;
  vertical-align: middle;
  text-align: center;
  padding: 12px 0;
  color: #666;
  font-size: 14px;
  width: 120px;
}

th {
  font-weight: normal;
}

.text_right {
  text-align: right;
  padding-right: 10px;
}

.other_bg {
  background-color: #f5f5f5
}
</style>
